<template>
    <div class="container note-warrp">
        <div class="noteL">
            <ul class="menu">
                <li class="cur item">
                    全部
                </li>
                <li class="item">
                    前端开发
                </li>
                <li class="item">
                    cocos creator
                </li>
            </ul>
        </div>
        <div class="noteR">
            <ul class="noteList">
                <li class="item" v-for="(item,index) in [1,2,3,4,5,6,7,8,9]" :key="index">
                    <div class="top">
                        <div class="topl">
                            <p class="time">
                                <span class="iconfont icon-shijian"></span>
                                12天前
                            </p>
                        </div>
                        <div class="topr">
                            <a href="" class="taga">前端开发</a>
                        </div>
                    </div>
                    <div class="content">
                        js字符串替换{{item}}
                    </div>
                    <pre v-highlight >
                        <code class="lang-javascript">
                            str.replace('原字符', '替换后的字符');  
                            console.log(str);  
                        </code>
                    </pre>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {

        }
    },
    methods: {
        handleCopyCodeSuccess() {
            console.log('复制成功~')
        }
    }
}
</script>

<style lang="scss" scoped>

</style>